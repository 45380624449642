import React, { useEffect,useState,useRef } from 'react'
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import Alert from '../functions/appAlert';
import useReportErr from '../hooks/useReportErr';
import { RotatingLines } from  'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';   
import usePageSettings from '../hooks/usePageSettings';
import {useGetData,usePutData} from '../hooks/useGetData';
import {Link} from 'react-router-dom';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'
import './RecepyIdeas2.css'
import { PiChefHatLight } from "react-icons/pi";



function RecepyIdeas() {


 const [getColorVal,setColorVal]=useState('')
 const [getImageVal,setImageVal]=useState('')
 const [getImageUrl,setImageUrl]=useState('')
 const [getRenderKey,setRenderKey]=useState(0) // this is the render key
 const[getIdeas,setIdeas]=useState([{}]) // this is the ideas
 const[getBackGroundShade,setBackGroundShade]=useState(0.55)
 const[getMenuShade,setMenuShade]=useState(0.95)
 const[getMenuShade2,setMenuShade2]=useState(0.75)
 const [getshowAlert,setShowAlert] = useState(false);
 const [getAlertMessage,setAlertMessage] = useState({});
 const[getAlertCode,setAlertCode]=useState(0)
 const [getSpinnerLoading,setSpinnerLoading]=useState(false)
 const [windowWidth,setWindowWith]=useState(0) // this is the window width
 const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
 const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
 const[getCountry,setCountry]=useState('se') // this is the country
 const pageid = 20 // this is the page id
 const[getSearchText,setSearchText]=useState('') // this is the search text
 const[getRecipeIdeas,setRecipeIdeas]=useState([]) // this is the recipe ideas
 const [showPopup,setShowPopup] = useState(false); // this is the show popup
 const [openPopupIndex, setOpenPopupIndex] = useState(null);
 const popupRef = useRef(null);
 const [recipeNameInformation, setRecipeNameInformation] = useState({}); // this is the recipe name information


    useEffect(()=>{
        let country = localStorage.getItem('country')||'en' // this is the country code
        setCountry(country)

        let recipeIdeas = localStorage.getItem('recipeIdeas');
        if (recipeIdeas) {
          setRecipeIdeas(JSON.parse(recipeIdeas));
        } else {
          setRecipeIdeas([]);
        }
      },[])

          // if language is japanese we use the font Zen Old Mincho
    let newFontFamily=''
    if(getCountry==='ja'){
    newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
    }else{
    newFontFamily='Arial, Helvetica, sans-serif'
    }
    document.documentElement.style.setProperty('--font-family', newFontFamily);



   
      const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
      const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server
  
      const navigate = useNavigate();

      useEffect(() => {
        function setSize() {
          setWindowWith(window.innerWidth);
          setWindowHeight(window.innerHeight);
        
          if(window.innerWidth < 600){
      
            setMobileIsUsed(true)
          } 
      
        }
    
      
        // Call setSize initially to set the correct state
        setSize();
      
        // Add the event listener
        window.addEventListener('resize', setSize);
      
        // Return a cleanup function to remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', setSize);
        };
      }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies
    

      
  function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }
  
//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--windowHeight',windowHeight+'px');

    
  }, [getBackGroundShade,getMenuShade,getMenuShade2]);


  // language settings
  const selectedCountry = languages[getCountry];


  let {
    recipesearchFieldset,
    recipesearchPlaceholder,
    recipesearchButton,
    recipesearchConfirmation,
    recipesearchConfirmationNum,
    recipesearchText,
    receipesearchHeaderId,
    receipesearchHeaderName,
    recipeTitle,
    titleAdjustMenu,
    titleAdjustMenu1,
  } = selectedCountry.recipeideas||{};

  const{
    recipeIdeaAccountVoid,
    recipeInformaionVoid
   }=selectedCountry.confirmationMessages || {}

   // error messages
 const selectedError=AppError[localStorage.getItem('country')||'en']
  
 useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);



      const RunReportErrHandler = async (errCode) => {
      
        setSpinnerLoading(true);
        await useReportErr('ErrReport',{errCode});
        setSpinnerLoading(false);
      };
      
      useEffect(() => {
        
        if (getAlertCode > 0) {
          RunReportErrHandler(getAlertCode);
        }
      }, [getAlertCode]);
      
      const handleCloseAlert = () => {
        setShowAlert(false);
      };





      // if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
    setBackGroundShade(val)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateBackGroundShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
    }catch(error){
        setAlertCode(17014)
        setAlertMessage(selectedError[17014])
        setSpinnerLoading(false)
        setShowAlert(true)
      
        return;
      }
  
  }
  
  async function SetmenuColorHandler(val){
    setMenuShade(val)
    setMenuShade2(val-0.20)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateMenuShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
  
  }catch(error){
    setAlertCode(17015)
    setAlertMessage(selectedError[17015])
    setSpinnerLoading(false)
    setShowAlert(true)
  
    return;
  }
  }

  async function GetRecepyIdeas() {
    setRecipeIdeas([]);
    setSpinnerLoading(true);
    //empty local storage
    localStorage.setItem('recipeIdeas', JSON.stringify([]));

    try {
        const response = await useGetData(`getRecepyIdeas/${getSearchText}`);
        if(response.status==201){
          setSpinnerLoading(false);
          setAlertCode(0);
          setAlertMessage(recipeIdeaAccountVoid);
          setShowAlert(true);
        
          return
        }
        const updatedRecipes = updateKeys(response.data.recipes);
        if(updatedRecipes.length===0){
          setAlertCode(17011);
          setAlertMessage(selectedError[17011]);
          setSpinnerLoading(false);
          setShowAlert(true);
          return;
        }
        //update local storage
        localStorage.setItem('recipeIdeas', JSON.stringify(updatedRecipes));
        setRecipeIdeas(updatedRecipes);
        setSpinnerLoading(false);

    } catch (error) {
      
        setAlertCode(17011);
        setAlertMessage(selectedError[17011]);
        setSpinnerLoading(false);
        setShowAlert(true);

        return;
    }
}

function updateKeys(recipes) {
    return recipes.map(recipe => {
        // If 'RecipeName' key is missing or empty, use the first value found or default to 'Unknown Recipe'
        if (!recipe.hasOwnProperty('RecipeName') || !recipe.RecipeName) {
            const value = Object.values(recipe)[0] || 'Unknown Recipe';
            return { RecipeName: value };
        }
        return recipe;
    });
}



    const setSearchTextHandler=(val)=>{
      setSearchText(val)
    }

    // here i want to count the number of recipes
    // i want to count the number of recipes
    let recipeCount = 0
    if(getRecipeIdeas && getRecipeIdeas.length>0){
    recipeCount = getRecipeIdeas.length
    }




  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenPopupIndex(null); // Close only if clicking outside
      }
    };

    if (openPopupIndex !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPopupIndex]);

  // recipe information
  const getRecipeInformationHandler = (recipeName) => {
    GetRecipeInformation(recipeName);
    return;
  }

  async function GetRecipeInformation(recipeName) {
     setSpinnerLoading(true);
    try {
      const response = await useGetData(`getRecipeInformation/${recipeName}`);
      if(response.status===200){
        setRecipeNameInformation(response.data.content);
        setSpinnerLoading(false);
      }else if(response.status===299){
        setSpinnerLoading(false);
        setAlertCode(0);
        setAlertMessage(recipeInformaionVoid);
        setShowAlert(true);
      }
    } catch (error) {
      setAlertCode(17012);
      setAlertMessage(selectedError[17016]);
      setSpinnerLoading(false);
      setShowAlert(true);
      return;
    }
  }







  return (
    <>
        {windowWidth<360?displayGadgetToSmall():''}
    <div className={getBackGroundShade<1?"xitotalArea":"xitotalArea1"}>
{getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xibackgroundImageRecepyNew" />:''}
          
<div className="xiheaderArea">
<div className={getBackGroundShade<1?"xicompName":"xicompName1"}>{recipeTitle}</div>
</div>

<div className="xirangeArea">
        <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
        title={titleAdjustMenu1} className="xirange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>

<input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
        title={titleAdjustMenu} className="xirange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>
</div>

<div className="topArea">
<div className="xiareaA">
<div className="xisearchHeader">{recipesearchFieldset}</div>
 <div className="xisearchTop">
<div className="xisearchWrapper">
    <textarea type="text" className="xisearchInput" placeholder={recipesearchPlaceholder}
     onChange={(e)=>setSearchTextHandler(e.target.value)}
    />
    <button className="xisearchButton" onClick={GetRecepyIdeas}>{recipesearchButton}</button>
    </div>
 </div>

 <div className="xiconfirmationArea">

{getRecipeIdeas && getRecipeIdeas.length>0?<button className="xiconfirmationButton">{recipesearchConfirmation} {recipeCount}{recipesearchConfirmationNum} {recipesearchText} </button>:''}


 </div>

 <div className="xirecepyIdeasArea">
    <div className="headerrow">
        <div className="xirecepyHeaderName">{receipesearchHeaderName}</div>
</div>
</div>

    <div className="xirecepyIdeasData">
       {getRecipeIdeas && getRecipeIdeas.map((data,index)=>{
  return( 
    <><div className="xidatarows">
      <React.Fragment key={index}>
        <Link
          to={{
            pathname: `/CreateNewRecepy/${data.RecipeName}`,
            state: { recipeName: data.RecipeName }
          }}
          className={index % 2 === 0 ? "xirecepyDataName" : "xirecepyDataName1"}
        >
          {/* Chef Hat - Prevent Link Click */}
          <PiChefHatLight
            className="xiChecfHat"
            title="Vad är detta! Klicka för mer information"
            onClick={(event) => {
              event.preventDefault(); // Prevent link navigation
              setOpenPopupIndex(index);getRecipeInformationHandler(data.RecipeName);
              setRecipeNameInformation('');
            } } />

          {/* Recipe Name (Clickable Link) */}
          <span className="ellipsis-text">{data.RecipeName}</span>
        </Link>
        {openPopupIndex === index && (
          <div ref={popupRef} className="xipopup-box">
            <textarea className="xipopup-box-content" readOnly value={recipeNameInformation} />

            <button onClick={() => setOpenPopupIndex(null)} className="close-btn">
              Stäng
            </button>
          </div>
        )}
      </React.Fragment>

    </div>
      </>
        )

      })}


</div>
  


</div>



<div className="xiSpinnerContainer">
        <div className="xiSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>
        {getshowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}

</div>
</div>

</>


  )
}

export default RecepyIdeas