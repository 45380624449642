
import { useState } from 'react'
import './StripeOnboarding.scss'

export default function StripeOnboarding({
    stripeOnBoardingHeaderText,
    completeSetuptext,
    stripeNescessaryText,
    completeStripeBoardingText,
    StripeIdText,
    goOnBoardText,
    receivingStripeIdText,
    handleOnboard,
    stripeOnBoardingLink,
    stripeCustomerId,
    userStripeAccountId,
    onBoadingDonetext,
    visitStripeAccountText

}) {

let userAccountId=userStripeAccountId[0].stripeAccountId
console.log(userAccountId)
console.log(goOnBoardText)

  return (
    <div className="stripe-onboarding">
      <h2 className="stripe-onboarding__title">{stripeOnBoardingHeaderText}</h2>
      <p className="stripe-onboarding__description">{completeSetuptext}</p>
      
      <div className="stripe-onboarding__content">
        <p className="stripe-onboarding__info">
          {stripeNescessaryText}
        </p>
   {!userAccountId||userAccountId == null ? ( // Checks for both null and undefined
  <button 
    onClick={handleOnboard} 
    className="stripe-onboarding__button"
  >
    {goOnBoardText}
  </button>
) : (
  <button 
    className="stripe-onboarding__button"
  >
    {userAccountId}
  </button>
)}


    
        
        
        {stripeOnBoardingLink && (
          <a href={stripeOnBoardingLink} target="_blank" rel="noopener noreferrer" className="stripe-onboarding__link">
            {completeStripeBoardingText}
          </a>
        )}
        
        {stripeCustomerId && (
          <div className="stripe-onboarding__id-container">
            <label htmlFor="stripe-id" className="stripe-onboarding__label">{StripeIdText}:</label>
            <input 
              id="stripe-id"
              value={stripeCustomerId}
              readOnly
              className="stripe-onboarding__input"
            />
          </div>
        )}
      </div>
      
      <p className="stripe-onboarding__footer">
        {userAccountId?onBoadingDonetext:receivingStripeIdText}
      </p>
  
  {userAccountId&&(
      <p className="stripe-onboarding__footer">
  <a 
    href="https://connect.stripe.com/express_login" 
    target="_blank" 
    rel="noopener noreferrer"
    className="stripe-onboarding__link"
  >
    {visitStripeAccountText}
  </a>
</p>
  )}




    </div>
    
  )
}
